export const option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: 'Invoice Status',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 12008500, name: 'Paid', itemStyle: {color: '#4CAF50'}, },
          { value: 1008900, name: 'Pending', itemStyle: {color: '#2196F3'} },
          { value: 98705, name: 'Cancelled', itemStyle: {color: '#F44336'} },
        ]
      }
    ]
};

  export const transform = () => {
    return option;
  }